import React, { useEffect } from "react";
import "../../src/css/Home/dash.css";
import DashSection from "../Pages/DashSection";
import ToggleSidebar from "../Pages/ToggleSidebar";
import Model from "../Pages/Model";
import "../index.css";
const Dashboard = () => {
  return (
    <>
      <div class="content">
        <section className="main-dash-container">
          {/* <Model/> */}
          <ToggleSidebar />
          <DashSection />
        </section>
      </div>
    </>
  );
};

export default Dashboard;
