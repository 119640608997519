import ToggleSidebar from "./ToggleSidebar";
import React, { useEffect, useState } from "react";
import { buyNewLevel} from "../helpers/web3";
import { useDispatch, useSelector } from "react-redux";


import { BsCartPlusFill } from "react-icons/bs";

import { Link } from "react-router-dom";
import { Loader } from "./Loader";
const WorkingTree = () => {
  const [loading, setLoading] = useState(false);

  const { walletAddress, workingTreeArray} = useSelector(
    (state) => state.user.value
  );
  const [dataarr, setdataArray] = useState([]);

  const dispatch = useDispatch();

  useEffect(() => {
    if (workingTreeArray.length > 0) {
      setdataArray(workingTreeArray);
      setLoading(true);
    }
  }, [workingTreeArray]);

  function getActiveInvest(usersarr, numberOfReinvest, i) {
    if (usersarr?.length > 0) {
      const isfill =
        numberOfReinvest !== 0
          ? usersarr.length / numberOfReinvest === 6
            ? true
            : false
          : false;
      //  const toindex = numberOfReinvest !== 0?(usersarr.length/numberOfReinvest)-numberOfReinvest:0
      const fromindex = Math.floor(usersarr.length / 6) * 6;
      const uarr = numberOfReinvest !== 0 ? usersarr.slice(fromindex) : [];
      //  console.log(usersarr.length,uarr.length,numberOfReinvest,fromindex,'dsfdfg',uarr)
      return !isfill
        ? numberOfReinvest === 0
          ? usersarr.length
          : uarr.length
        : [];
    } else {
      return [];
    }
  }

  return (
    <div>
      <section className="main-dash-container">
        <ToggleSidebar />

        <div className="container" style={{ paddingTop: "100px" }}>
          <h2 className="text-center text-warning dash-heading pb-3 dash-heading">Working Structure</h2>
          <div id="ContentPlaceHolder1_UpdatePanel1">
            <div className="row d-flex">
              {loading === false ? (
                <Loader />
              ) : (
                dataarr &&
                dataarr?.map((data, i) => {
                  
                  return (
                    <div
                      id="ContentPlaceHolder1_DAI11Tree"
                      className={`col-md-3 ${
                        data?.active === true ||
                        i == 0 ||
                        dataarr[i - 1]?.active === true
                          ? ""
                          : "opacity-25 body-disablesection"
                      }`}
                      key={i + "usermatrixlevel"}
                    >
                      <div className="mytreebox">
                        <div className="mytreebox-header">
                          <div className="mytreebox-lbl">
                            Slot {dataarr[i]?.level}
                          </div>
                          <div className="mytreebox-sloat">S 6</div>
                          <div className="mytreebox-dai">
                            {" "}
                            {dataarr[i]?.price} <span className="dai"></span>
                          </div>
                        </div>
                        {data.active == true ? (
                          <Link to={`/workingtable/${dataarr[i]?.level}`}>
                            <div className="mytreebox-body">
                              <div className="mytree">
                                <div className="mytree-l1">
                                  <div
                                    id="ContentPlaceHolder1_da102"
                                    className="mytree-item "
                                  >
                                    <div className="mytree-icon">&nbsp;</div>
                                  </div>
                                </div>
                                <div className="mytree-l2">
                                  <div
                                    id="ContentPlaceHolder1_l110"
                                    className={`mytree-item ${
                                      getActiveInvest(
                                        data?.users,
                                        Number(data?.total_reentry),
                                        i
                                      ) >= 1
                                        ? ""
                                        : "deactiv"
                                    }`}
                                  >
                                    <div
                                      className="mytree-icon "
                                      data-toggle="tooltip"
                                      title="1"
                                    >
                                      &nbsp;
                                    </div>
                                    <div className="mytree-l3">
                                      <div
                                        id="ContentPlaceHolder1_l112"
                                        className={`mytree-item ${
                                          getActiveInvest(
                                            data?.users,
                                            Number(data?.total_reentry),
                                            i
                                          ) >= 3
                                            ? ""
                                            : "deactiv"
                                        }`}
                                      >
                                        <div className="mytree-icon">
                                          &nbsp;
                                        </div>
                                      </div>
                                      <div
                                        id="ContentPlaceHolder1_l113"
                                        className={`mytree-item ${
                                          getActiveInvest(
                                            data?.users,
                                            Number(data?.total_reentry),
                                            i
                                          ) >= 4
                                            ? ""
                                            : "deactiv"
                                        }`}
                                      >
                                        <div className="mytree-icon">
                                          &nbsp;
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                  <div
                                    id="ContentPlaceHolder1_l111"
                                    className={`mytree-item ${
                                      getActiveInvest(
                                        data?.users,
                                        Number(data?.total_reentry),
                                        i
                                      ) >= 2
                                        ? ""
                                        : "deactiv"
                                    }`}
                                  >
                                    <div className="mytree-icon">&nbsp;</div>
                                    <div className="mytree-l3">
                                      <div
                                        id="ContentPlaceHolder1_l114"
                                        className={`mytree-item ${
                                          getActiveInvest(
                                            data?.users,
                                            Number(data?.total_reentry),
                                            i
                                          ) >= 5
                                            ? ""
                                            : "deactiv"
                                        }`}
                                      >
                                        <div className="mytree-icon">
                                          &nbsp;
                                        </div>
                                      </div>
                                      <div
                                        id="ContentPlaceHolder1_l115"
                                        className={`mytree-item ${
                                          getActiveInvest(
                                            data?.users,
                                            Number(data?.total_reentry),
                                            i
                                          ) >= 6
                                            ? ""
                                            : "deactiv"
                                        }`}
                                      >
                                        <div className="mytree-icon">
                                          &nbsp;
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                            <div class="mytreebox-footer">
                              <div class="mytreebox-footer-content">
                                <div id="user8" class="mytreebox-users">
                                  <i class="treeusers-icon"></i>
                                  <span id="ContentPlaceHolder1_LblU8">
                                    {dataarr[i]?.total_member}
                                  </span>
                                </div>
                                <div id="user8" class="mytreebox-trans">
                                  <i class="treetrans-icon"></i>
                                  <span id="ContentPlaceHolder1_LblU8">
                                    {dataarr[i]?.total_reentry}
                                  </span>
                                </div>
                              </div>
                              <div class="treegrowth">
                                <div class="growth-per">
                                  <span id="ContentPlaceHolder1_Lblpg8">
                                    {getActiveInvest(
                                      data?.users,
                                      Number(data?.total_reentry),
                                      i
                                    ) === 1
                                      ? "16.66%"
                                      : getActiveInvest(
                                          data?.users,
                                          Number(data?.total_reentry),
                                          i
                                        ) === 2
                                      ? "33.32%"
                                      : getActiveInvest(
                                          data?.users,
                                          Number(data?.total_reentry),
                                          i
                                        ) === 3
                                      ? "50%"
                                      : getActiveInvest(
                                          data?.users,
                                          Number(data?.total_reentry),
                                          i
                                        ) === 4
                                      ? "66.64%"
                                      : getActiveInvest(
                                          data?.users,
                                          Number(data?.total_reentry),
                                          i
                                        ) === 5
                                      ? "83.3%"
                                      : getActiveInvest(
                                          data?.users,
                                          Number(data?.total_reentry),
                                          i
                                        ) === 6
                                      ? "100%"
                                      : "0%"}
                                  </span>
                                </div>
                                
                                <div class="progress">
                                  <div
                                    id="ContentPlaceHolder1_pg8"
                                    class="progress-bar bg-success"
                                    style={{
                                      width: `${getActiveInvest(
                                      data?.users,
                                      Number(data?.total_reentry),
                                      i
                                    ) === 1
                                      ? "16.66%"
                                      : getActiveInvest(
                                          data?.users,
                                          Number(data?.total_reentry),
                                          i
                                        ) === 2
                                      ? "33.32%"
                                      : getActiveInvest(
                                          data?.users,
                                          Number(data?.total_reentry),
                                          i
                                        ) === 3
                                      ? "50%"
                                      : getActiveInvest(
                                          data?.users,
                                          Number(data?.total_reentry),
                                          i
                                        ) === 4
                                      ? "66.64%"
                                      : getActiveInvest(
                                          data?.users,
                                          Number(data?.total_reentry),
                                          i
                                        ) === 5
                                      ? "83.3%"
                                      : getActiveInvest(
                                          data?.users,
                                          Number(data?.total_reentry),
                                          i
                                        ) === 6
                                      ? "100%"
                                      : "0%"}`
                                    }}
                                    progress-bar bg-success="0.0"
                                  ></div>
                                </div>
                              </div>
                            </div>
                          </Link>
                        ) : dataarr[i - 1]?.active === true || i == 0 ? (
                          <div
                            className="mytreebox-body body-section"
                            onClick={() => {
                              buyNewLevel(
                                dataarr[i]?.level,
                                dataarr[i]?.price,
                                walletAddress,
                                dispatch
                              );
                            }}
                          >
                            <div className="text-center pt-5 pb-5">
                              {" "}
                              <h6 className="text-warning dash-heading">Activate </h6>
                              <BsCartPlusFill
                                color="green"
                                size={45}
                                className="mb-4"
                              />
                            </div>
                          </div>
                        ) : (
                          <>
                            <div className="mytreebox-body">
                              <div className="mytree">
                                <div className="mytree-l1">
                                  <div
                                    id="ContentPlaceHolder1_da102"
                                    className="mytree-item deactiv"
                                  >
                                    <div className="mytree-icon">&nbsp;</div>
                                  </div>
                                </div>
                                <div className="mytree-l2">
                                  <div
                                    id="ContentPlaceHolder1_l110"
                                    className="mytree-item deactiv"
                                  >
                                    <div className="mytree-icon">&nbsp;</div>
                                    <div className="mytree-l3">
                                      <div
                                        id="ContentPlaceHolder1_l112"
                                        className="mytree-item deactiv"
                                      >
                                        <div className="mytree-icon">
                                          &nbsp;
                                        </div>
                                      </div>
                                      <div
                                        id="ContentPlaceHolder1_l113"
                                        className="mytree-item deactiv"
                                      >
                                        <div className="mytree-icon">
                                          &nbsp;
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                  <div
                                    id="ContentPlaceHolder1_l111"
                                    className="mytree-item deactiv"
                                  >
                                    <div className="mytree-icon">&nbsp;</div>
                                    <div className="mytree-l3">
                                      <div
                                        id="ContentPlaceHolder1_l114"
                                        className="mytree-item deactiv"
                                      >
                                        <div className="mytree-icon">
                                          &nbsp;
                                        </div>
                                      </div>
                                      <div
                                        id="ContentPlaceHolder1_l115"
                                        className="mytree-item deactiv"
                                      >
                                        <div className="mytree-icon">
                                          &nbsp;
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                            <div class="mytreebox-footer">
                              <div class="mytreebox-footer-content">
                                <div id="user8" class="mytreebox-users">
                                  <i class="treeusers-icon"></i>
                                  <span id="ContentPlaceHolder1_LblU8">0</span>
                                </div>
                                <div id="user8" class="mytreebox-trans">
                                  <i class="treetrans-icon"></i>
                                  <span id="ContentPlaceHolder1_LblU8">0</span>
                                </div>
                              </div>
                              <div class="treegrowth">
                                <div class="growth-per">
                                  <span id="ContentPlaceHolder1_Lblpg8">0</span>
                                </div>
                                <div class="progress">
                                  <div
                                    id="ContentPlaceHolder1_pg8"
                                    class="progress-bar bg-success"
                                    style={{ width: "10%" }}
                                    progress-bar
                                    bg-success="0.0"
                                  ></div>
                                </div>
                              </div>
                            </div>
                          </>
                        )}
                      </div>
                    </div>
                  );
                })
              )}
            </div>
          </div>
        </div>
      </section>
    </div>
  );
};

export default WorkingTree;
