import React, { useEffect, useState } from "react";
import { AiFillWallet, AiOutlineUsergroupAdd } from "react-icons/ai";

import {
  checkUsersActiveX6Level,
  getBalance,
  lastUserId,
  TokenBalnce,
  users,
} from "../helpers/web3";
import { useDispatch, useSelector } from "react-redux";

import { setUserInfo } from "../helpers/redux/dataSlice";
import { Link } from "react-router-dom";
import {
  copy_contract,
  copy_refferal,
  copy_wallet,
} from "../helpers/helperFunction";
import { MdContentCopy } from "react-icons/md";
import navimg from "../image/logo.png";
import {
  GiCrownCoin,
  GiProfit,
  GiReceiveMoney,
  GiTakeMyMoney,
} from "react-icons/gi";
import { HiUserGroup } from "react-icons/hi";
import { BsArrowRightShort, BsArrowUpRight } from "react-icons/bs";

import {
  getRoyalityBonusIncome,
  getTotalTeam,
  getUserRandomId,
  getUserRandomIdetail,
} from "../helpers/api";
import { Loader } from "./Loader";

const DashSection = () => {
  const [loading, setLoading] = useState(true);
  const [allParticipants, setAllParticipants] = useState(0);
  const [randomUserId, setUserRandomId] = useState("");
  const [maticBalance, setBalanceMatic] = useState("");
  const [tokenBalance, getBalanceToken] = useState("");
  const [rewardincome, setRewardIncome] = useState("");
  const level = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12];
  const { walletAddress, userInfo } = useSelector((state) => state.user.value);
  const dispatch = useDispatch();

  useEffect(() => {
    users(walletAddress).then((res) => {
      dispatch(setUserInfo({ userInfo: res }));
    });
    getBalance(walletAddress).then((res) => {
      setBalanceMatic(res);
    });
    TokenBalnce(walletAddress).then((res) => {
      getBalanceToken(res);
    });
  }, [walletAddress, loading]);

  useEffect(() => {
    var tt;
    if (userInfo?.id) {
      tt = setInterval(() => {
        getUserRandomIdetail(userInfo?.id).then((res) => {
          if (res?.length > 0) {
            setLoading(false);
            clearInterval(tt);
            setUserRandomId(res[0]?.user_id);
          }
        });
      }, 2000);
    }
    return () => clearInterval(tt);
  }, [walletAddress, userInfo?.id]);

  useEffect(() => {
    getTotalTeam(randomUserId).then((res) => {
      setAllParticipants(res);
    });
  }, [randomUserId]);
  useEffect(() => {
    getRoyalityBonusIncome(walletAddress).then((res) => {
      setRewardIncome(res);
    });
  }, [walletAddress]);
  // console.log(rewardincome);
  return (
    <>
      <div className="container">
        {loading ? (
          <Loader />
        ) : (
          <div
            className=""
            id="ContentPlaceHolder1_UpdatePanel1"
            style={{ marginTop: "120px" }}
          >
            <div className="row custom_header">
              <div class="col-md-3 col-sm-6 col-12 logo_div">
                <div className="d-flex justify-content-center pb-2">
                  <div>
                    <img
                      src={navimg}
                      className="nav-image"
                      alt=""
                      height={50}
                    />
                  </div>
                  <div>
                    <h5 className="text-white">{randomUserId}</h5>
                    <p className="text-warning">
                      {walletAddress.slice(0, 8)}...
                      {walletAddress.slice(-7)}
                    </p>
                  </div>
                </div>
              </div>
              <div class="col-md-9 col-sm-6 col-12 text-end btn_div">
                <button type="button" class="btn btn-primary me-3 mb-3">
                  {maticBalance ? maticBalance?.toFixed(4) : 0} BNB
                </button>
                <button type="button" class="btn btn-primary me-3 mb-3">
                  {" "}
                  {walletAddress.slice(0, 4)}...
                  {walletAddress.slice(-3)} <AiFillWallet />
                </button>
                <button type="button" class="btn btn-primary mb-3">
                  {(tokenBalance / 1e18)?.toFixed(2)} Dai
                </button>
              </div>
            </div>
            <div class="widget-box border-gradient border-gradient-purple mb-4">
              <div class="widget-body ">
                <div class="box-content">
                  <div class="form-horizontal">
                    <br />

                    <div class="form-group">
                      <div class="row">
                        <div class="col-md-2 text-center">
                          <h5 className="text-white pb-3 dash-heading">
                            Slot Details :
                          </h5>
                        </div>

                        <div class="col-md-8 text-center">
                          <div class="myslots">
                            {level.map((data, i) => {
                              return <LevelRow level={data} />;
                            })}
                          </div>
                        </div>
                      </div>
                      <div class="row">
                        <Link to="/workingTree">
                          <div className="text-center mt-3">
                            <button
                              type="button"
                              class="btn btn-primary preview-button-dash"
                            >
                              Preview <BsArrowUpRight />
                            </button>
                          </div>
                        </Link>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div class="widget-body111">
              <div class="row">
                <div class="col-md-3 col-sm-4 col-6 ">
                  <Link to="/partners">
                    <div class="infobox border-gradient border-gradient-purple">
                      <div class="infobox-icon bg-light-warning">
                        <AiOutlineUsergroupAdd size={35} />
                      </div>
                      <div class="infobox-data">
                        <span class="infobox-data-number text-white text-white">
                          {userInfo?.partnersCount}
                        </span>
                        <div class="infobox-content pt-2"> Direct Partners</div>
                      </div>
                    </div>
                  </Link>
                </div>

                <div class="col-md-3 col-sm-4 col-6 ">
                  <div class="infobox border-gradient border-gradient-purple">
                    <div class="infobox-icon bg-light-warning">
                      <HiUserGroup size={35} />
                    </div>
                    <div class="infobox-data">
                      <span class="infobox-data-number text-white">
                        {allParticipants}
                      </span>
                      <div class="infobox-content pt-2">
                        Total Team Partners
                      </div>
                    </div>
                  </div>
                </div>

                <div class="col-md-3 col-sm-4 col-6 ">
                  <Link to="/userIncome">
                    <div class="infobox border-gradient border-gradient-purple">
                      <div class="infobox-icon bg-light-warning">
                        <GiProfit size={35} />
                      </div>
                      <div class="infobox-data">
                        <span class="infobox-data-number text-white">
                          {(userInfo?.workingIncome / 1e18)?.toFixed(2)}
                          <span class="dai-dash">&nbsp;</span>
                        </span>
                        <div class="infobox-content pt-2">Working Income</div>
                      </div>
                    </div>
                  </Link>
                </div>

                <div class="col-md-3 col-sm-4 col-6 ">
                  <Link to="/userIncome">
                    <div class="infobox border-gradient border-gradient-purple">
                      <div class="infobox-icon bg-light-warning">
                        <GiReceiveMoney size={35} />
                      </div>
                      <div class="infobox-data">
                        <span class="infobox-data-number text-white">
                          {(userInfo?.nonWorkingIncome / 1e18)?.toFixed(2)}
                          <span class="dai-dash">&nbsp;</span>
                        </span>
                        <div class="infobox-content pt-2">
                          Non-Working Income
                        </div>
                      </div>
                    </div>
                  </Link>
                </div>

                <div class="col-md-3 col-sm-4 col-6 ">
                  <div class="infobox border-gradient border-gradient-purple">
                    <div class="infobox-icon bg-light-warning">
                      <GiCrownCoin size={35} />
                    </div>
                    <div class="infobox-data">
                      <span class="infobox-data-number text-white">
                        {rewardincome?.details[0]?.royalty}{" "}
                        <span class="dai-dash">&nbsp;</span>
                      </span>

                      <div class="infobox-content pt-2"> Royality Bonus</div>
                    </div>
                  </div>
                </div>

                <div class="col-md-3 col-sm-4 col-6 ">
                  <div class="infobox border-gradient border-gradient-purple">
                    <div class="infobox-icon bg-light-warning">
                      <GiTakeMyMoney size={35} />
                    </div>
                    <div class="infobox-data">
                      <span class="infobox-data-number text-white">
                        {rewardincome?.details[0]?.reward}
                        <span class="dai-dash">&nbsp;</span>
                      </span>

                      <div class="infobox-content pt-2">Reward Bonus</div>
                    </div>
                  </div>
                </div>
              
                <div class="col-md-3 col-sm-4 col-6 ">
                  <div class="infobox border-gradient border-gradient-purple">
                    <div class="infobox-icon bg-light-warning">
                      <GiCrownCoin size={35} />
                    </div>
                    <div class="infobox-data">
                      <button
                        class="btn btn-primary"
                        style={{ padding: "0px 20px" }}
                      >
                        View{" "}
                        <svg
                          stroke="currentColor"
                          fill="currentColor"
                          stroke-width="0"
                          viewBox="0 0 16 16"
                          height="1em"
                          width="1em"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path
                            fill-rule="evenodd"
                            d="M14 2.5a.5.5 0 0 0-.5-.5h-6a.5.5 0 0 0 0 1h4.793L2.146 13.146a.5.5 0 0 0 .708.708L13 3.707V8.5a.5.5 0 0 0 1 0v-6z"
                          ></path>
                        </svg>
                      </button>

                      <div class="infobox-content pt-2">Royality Details</div>
                    </div>
                  </div>
                </div>

                <div class="col-md-3 col-sm-4 col-6 ">
                  <div class="infobox border-gradient border-gradient-purple">
                    <div class="infobox-icon bg-light-warning">
                      <GiTakeMyMoney size={35} />
                    </div>
                    <div class="infobox-data">
                      <span class="infobox-data-number text-white">
                        {Number(userInfo?.workingIncome ?? 0) / 1e18 +
                          Number(userInfo?.nonWorkingIncome ?? 0) / 1e18 +
                          Number(rewardincome?.details[0]?.reward ?? 0) +
                          Number(rewardincome?.details[0]?.royalty ?? 0)}

                        <span class="dai-dash">&nbsp;</span>
                      </span>

                      <div class="infobox-content pt-2">Total Income</div>
                    </div>
                  </div>
                </div>

                <div class="col-md-3 col-sm-3 col-xs-12">
                  <div class="infobox d-block border-gradient border-gradient-purple">
                    <div>
                      <p class="infobox-content pt-2 d-block">My Link:</p>

                      <div className="input-group mb-3 address">
                        <input
                          id="copy"
                          type="text"
                          readOnly
                          className="form-control"
                          value={`https://app.earnexpress.space/register?${randomUserId}`}
                        />
                        <button
                          className="input-group-text"
                          onClick={() =>
                            copy_refferal(
                              `https://app.earnexpress.space/register?${randomUserId}`
                            )
                          }
                        >
                          <MdContentCopy />
                        </button>
                      </div>
                    </div>
                  </div>
                </div>

                <div class="col-md-3 col-sm-3 col-xs-12">
                  <div class="infobox d-block border-gradient border-gradient-purple">
                    <div>
                      <p class="infobox-content pt-2 d-block">
                        Wallet Address:
                      </p>

                      <div className="input-group mb-3">
                        <input
                          id="copywallet"
                          type="text"
                          readOnly
                          className="form-control"
                          value={walletAddress}
                        />
                        <button
                          className="input-group-text"
                          onClick={() => copy_wallet(walletAddress)}
                        >
                          <MdContentCopy />
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        )}
      </div>
    </>
  );
};

const LevelRow = (props) => {
  const [levelstatus, setLevelStatus] = useState(false);
  const { walletAddress } = useSelector((state) => state.user.value);
  useEffect(() => {
    checkUsersActiveX6Level(walletAddress, props.level).then((res) => {
      setLevelStatus(res);
    });
  }, [walletAddress]);
  return (
    <div
      id="ContentPlaceHolder1_l1"
      class={`slot-item ${levelstatus ? "active" : ""}`}
    >
      {props.level}
    </div>
  );
};
export default DashSection;
