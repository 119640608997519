import RoutesMain from "./Routes";
import "react-notifications/lib/notifications.css";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { NotificationContainer } from "react-notifications";
import { Toaster } from "react-hot-toast";
import { Provider } from "react-redux";
import { store } from "./helpers/redux/store";
function App() {
  return (
    <>
      <Provider store={store}>
        <RoutesMain />
        <ToastContainer theme="colored"/>
        <NotificationContainer />
        <Toaster
          position="top-center"
          autoClose={5000}
          hideProgressBar={false}
          newestOnTop={false}
          closeOnClick
          rtl={false}
          pauseOnFocusLoss
          draggable
          pauseOnHover
        />
      </Provider>
    </>
  );
}

export default App;
