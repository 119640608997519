import ToggleSidebar from "./ToggleSidebar";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { setNonWorkingTreeArray } from "../helpers/redux/dataSlice";
import { getAllUsersNonWorkingTree } from "../helpers/api";
import { Loader } from "./Loader";
import { checkUsersnonWorkingIncome } from "../helpers/web3";
const NonWorkingTree = () => {
  const { walletAddress } = useSelector((state) => state.user.value);
  const [dataarr, setdataArray] = useState([]);
  const [loading, setLoading] = useState(false);
  const dispatch = useDispatch();
  useEffect(() => {
    getAllUsersNonWorkingTree(walletAddress).then(async (res) => {
      if (res.length > 0) {
        const fArray = res.map(async (data, i) => {
          return await checkUsersnonWorkingIncome(walletAddress, i + 1).then(
            (res1) => {
              let oldArray = { ...data };
              if (res1) {
                oldArray.users = [{}, {}];
                return oldArray;
              }
              return oldArray;
            }
          );
        });

        setdataArray(await Promise.all(fArray));
      }
      // setdataArray(res)
      dispatch(setNonWorkingTreeArray({ data: res }));
      setLoading(true);
    });
  }, [walletAddress]);
  // console.log(dataarr, "dataarr");
  return (
    <>
      <section className="main-dash-container">
        <ToggleSidebar />
        <div className="container" style={{ paddingTop: "100px" }}>
          <h2 className="text-center text-warning dash-heading pb-3">
            Non-Working Structure
          </h2>
          <div id="ContentPlaceHolder1_UpdatePanel1">
            <div className="row d-flex">
              {loading === false ? (
                <Loader />
              ) : (
                dataarr &&
                dataarr?.map((data, i) => {
                  return (
                    <div
                      id="ContentPlaceHolder1_DAI8Tree"
                      class="col-md-3"
                      style={{ opacity: data?.is_active ? 1 : 0.2 }}
                      key={i + "nonworkingtree"}
                    >
                      <div class="mytreebox">
                        <div class="mytreebox-header">
                          <div class="mytreebox-lbl">Slot {data?.level}</div>
                          <div class="mytreebox-sloat">S 2</div>
                          <div class="mytreebox-dai">
                            {" "}
                            {data?.price} <span class="dai"></span>
                          </div>
                        </div>
                        {/* <Link
                          to={
                            data?.is_active
                              ? `/nonworkingtable/${dataarr[i]?.level}`
                              : `/nonworkingtree`
                          }
                        > */}
                        <div class="mytreebox-body">
                          <div class="mytree">
                            <div class="mytree-l1">
                              <div
                                id="ContentPlaceHolder1_da112"
                                class="mytree-item"
                              >
                                <div class="mytree-icon">&nbsp;</div>
                              </div>
                            </div>
                            <div class="mytree-l2">
                              <div
                                id="ContentPlaceHolder1_l810"
                                className={`mytree-item ${
                                  data?.users?.length >= 1 ? "" : "deactiv"
                                }`}
                              >
                                <div class="mytree-icon">&nbsp;</div>
                              </div>

                              <div
                                id="ContentPlaceHolder1_l811"
                                className={`mytree-item ${
                                  data?.users?.length >= 2 ? "" : "deactiv"
                                }`}
                              >
                                <div class="mytree-icon">&nbsp;</div>
                              </div>
                            </div>
                          </div>
                        </div>

                        <div class="mytreebox-footer">
                          <div class="mytreebox-footer-content">
                            <div id="user8" class="mytreebox-users">
                              <i class="treeusers-icon"></i>
                              <span id="ContentPlaceHolder1_LblU8">
                                {dataarr[i]?.users.length}
                              </span>
                            </div>
                            <div id="user8" class="mytreebox-trans">
                              <i class="treetrans-icon"></i>
                              <span id="ContentPlaceHolder1_LblU8">
                                {dataarr[i]?.total_reentry}
                              </span>
                            </div>
                          </div>
                          <div class="treegrowth">
                            <div class="growth-per">
                              <span id="ContentPlaceHolder1_Lblpg8">
                                {data?.users?.length == 1
                                  ? "50%"
                                  : data?.users?.length == 2
                                  ? "100%"
                                  : "0%"}
                              </span>
                            </div>
                            <div class="progress">
                              <div
                                id="ContentPlaceHolder1_pg8"
                                class="progress-bar bg-success"
                                style={{
                                  width: `${
                                    data?.users?.length == 1
                                      ? "50%"
                                      : data?.users?.length == 2
                                      ? "100%"
                                      : "0%"
                                  }`,
                                }}
                                progress-bar
                                bg-success="0.0"
                              ></div>
                            </div>
                          </div>
                        </div>
                        {/* </Link> */}
                      </div>
                    </div>
                  );
                })
              )}
            </div>{" "}
          </div>
        </div>
      </section>
    </>
  );
};

export default NonWorkingTree;