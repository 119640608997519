import axios from "axios";
const baseUrl="https://api.earnexpress.space/api"
export const getUserRandomIdetail = async (userId) => {
 
  try {
    const data = await axios.get(
      `${baseUrl}/registration_details/${userId}`
    );
   
    return data.data.data;
  } catch (e) {
    // console.log('dincatch:',e)
    return [];
  }
};

export const getTotalTeam = async(randomId) =>{
  console.log(randomId,"random::")
  const data = await axios.get(
    `${baseUrl}/direct_referrals/${randomId}`
  );
  
  return data.data.data;
}

export const getUserRandomId = async (randomId) => {
  try {
    const data = await axios.get(
      `${baseUrl}/registration/${randomId}`
    );

    return data.data.data;
  } catch (e) {
    // console.log('dincatch:',e)
    return [];
  }
};
export const getDirectTeam = async (userId ,page) => {

  
  try {
    const data = await axios.get(
      `${baseUrl}/registration_referral/${userId}?page=${page}`
    );
   
    return data.data.data;
  } catch (e) {
    // console.log('dincatch:',e)
    return [];
  }
};
export const getAllUsersIncome = async (userAddress,page) => {
  try {
    const data = await axios.get(
      `${baseUrl}/user_income/${userAddress}?page=${page}`
    );

    return data.data.data;
  } catch (e) {
    return [];
  }
};
export const getAllUsersWorkingTree = async (userAddress) => {
  const data = await axios.get(
    `${baseUrl}/new_user_place/${userAddress}`
  );

  return data.data.data;
};

export const getAllUsersNonWorkingTree = async (userAddress) => {
  const data = await axios.get(
    `${baseUrl}/new_user_place_inactive/${userAddress}`
  );

  return data.data.data;
};

export const getRoyalityBonusIncome = async (userAddress) => {
  const data = await axios.get(
    `${baseUrl}/total_reward/${userAddress}`
  );

  return data.data;
};
