import React, { useEffect, useState } from "react";
import moment from "moment/moment";
import ToggleSidebar from "./ToggleSidebar";
import { useSelector } from "react-redux";
import { useParams } from "react-router-dom";

export const NonWorkingTable = () => {
  const [apidata, setApidata] = useState();
    const { nonWorkingTreeArray } = useSelector((state) => state.user.value);
    const params = useParams()
    const {level} = params
    useEffect(() => {
      if(nonWorkingTreeArray.length>0){
        const arr = [...nonWorkingTreeArray[level-1].users]
        setApidata(arr)
      }      
      }, [nonWorkingTreeArray,params]);
  return (
    <>
      <section className="main-dash-container">
        <ToggleSidebar />
        <div className="container" style={{ paddingTop: "100px" }}>
          <h2 className="text-center text-warning pb-2 dash-heading">Non-Working Partners Detail</h2>
          <div className="row">
          <div className="table-responsive table-dark user-table user-dash-section  p-2">
              <table class="table table-borderless table-striped">
                <thead>
                  <tr>
                    <th className="table-head"># </th>
                    <th className="table-head">User ID</th>
                    <th className="table-head">User Address</th>
                    <th className="table-head">Place</th>
                  
                  </tr>
                </thead>
              
                <tbody className="table-data">
                  {apidata?.length > 0 ? (
                    apidata?.map((e, index) => {
                    
                      return (
                        <tr
                          className="  p-2"
                          style={{ marginBlock: "0px 15px" }}
                          key={index+'Nondirectincome'}
                        >
                          <td className="">
                            <span>{index + 1}</span>
                          </td>
                          <td className="">
                            <span>
                              {e?.user_id}
                            </span>
                          </td>
                          <td className="">
                            <span>
                              {e?.user?.slice(0, 6)+"..."+e?.user?.slice(30)}
                            </span>
                          </td>
                          <td className="">
                            <span>{e?.place} </span>
                          </td>

                          {/* <td className="">
                            {moment(Number(e.block_timestamp)*1000).fromNow()}
                            {}
                          </td> */}
                        </tr>
                      );
                    })
                  ) : (
                    <tr><td colSpan={5}> <div className="text-center text-white mt-4 mb-2 fw-bold fs-5">
                    No Data Found.
                  </div></td></tr>

                   
                  )}
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};
