
import { getAllUsersWorkingTree } from "./api";
import { toast } from 'react-toastify';
import { CONTRACT_ADDRESS } from "./config";
import {
  setRefresh,
  setUserExist,
  setWalletAddress,
  setWorkingTreeArray,
} from "./redux/dataSlice";
import { getLevelMulticall, isUserExists, startNow } from "./web3";

export function copy_refferal(refral) {
  var copyText = document.getElementById("copy");
  copyText.select();
  document.execCommand("copy");
  toast.success(refral)

}

export function copy_wallet(wallet) {
  var copyText = document.getElementById("copywallet");
  copyText.select();
  document.execCommand("copy");
  toast.success( wallet);
}
export function copy_contract() {
  var copyText = document.getElementById("copycontract");
  copyText.select();
  document.execCommand("copy");
  // NotificationManager.info("Contract address Copied!");
}

export function sideBar() {
  var btn = document.querySelector(".toggle");
  var btnst = true;
  btn.onclick = function () {
    if (btnst == true) {
      document.querySelector(".toggle span").classList.add("toggle");
      document.getElementById("sidebar").classList.add("sidebarshow");
      btnst = false;
    } else if (btnst == false) {
      document.querySelector(".toggle span").classList.remove("toggle");
      document.getElementById("sidebar").classList.remove("sidebarshow");
      btnst = true;
    }
  };
}
export function verifyUser(dispatch,address,nf) {
  startNow()
    .then((res) => {
      const fadd = address?address:res?.userAddress
      localStorage.setItem("volcano_waddress", fadd);
      dispatch(setWalletAddress({ walletAddress: fadd }));
      isUserExists(fadd).then((res) => {
        if(res){
        dispatch(setUserExist({ userExist: res }));
        dispatch(setRefresh({ data: true }));
        nf.success(
          "Signin Successfuly !"
        );
        }else{
        dispatch(setUserExist({ userExist: res }));
        dispatch(setRefresh({ data: true }));
          nf.error(
            "User Not Exist !"
          );
        }
      });
    })
    .catch((e) => {
      console.log("errorinstartnow", e);
    });
}

export function getActiveWallet(){
 return startNow()
    .then((res) => res)
    .catch((e) => {
      console.log("errorinstartnow", e);
    });
}

export async function fetchActiveLevel(walletAddress, dispatch) {
  try{
  getAllUsersWorkingTree(walletAddress).then(async (res) => {
    // fetchis_active(res);
    if (res.length > 0) {
      const resarr = res.map((item, i) => {
        const obj = {
          address: CONTRACT_ADDRESS,
          name: "usersActiveX6Levels",
          params: [walletAddress, i + 1],
        };
        return { ...obj };
      });
      const result = await getLevelMulticall(resarr, res);
      
      dispatch(setWorkingTreeArray({ data: result }));
    } else {
      dispatch(setWorkingTreeArray({ data: [] }));
    }
  }).catch((e)=>console.log("checklevelactiveerror::",e));
}catch(e){
   console.log("checkactivemulti::",e)
}
}

