import React, { useEffect, useState } from "react";
import ToggleSidebar from "./ToggleSidebar";
import { useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { usersX6HoldAmount, withdrawHoldingAnount } from "../helpers/web3";
import "../css/Home/dash.css"
const WorkingTable = () => {
  const [apidata, setApidata] = useState([]);
  const [refresh, setRefresh] = useState(false);
  const [holdingAmt, setHoldingAmt] = useState(0);
  const { workingTreeArray, walletAddress } = useSelector(
    (state) => state.user.value
  );
  const params = useParams();
  const { level } = params;
  useEffect(() => {
    
    if (workingTreeArray.length > 0) {
      const arr = [...workingTreeArray[level - 1].users];
      setApidata(arr);
    }

   

    usersX6HoldAmount(walletAddress, level).then((res) => {
      setHoldingAmt(res);
      
    });
  }, [workingTreeArray, params, walletAddress ,refresh]);
  return (
    <>
      <section className="main-dash-container">
        <ToggleSidebar />
        <div className="container" style={{ paddingTop: "100px" }}>
          <h2 className="text-center text-warning pb-2 dash-heading">
            Working Patners Details
          </h2>
          <div className="row">
            <div className="table-responsive table-dark user-table user-dash-section  p-2">
            {holdingAmt>0?
              <div className="margin-auto" >
                <div class="col-md-3 col-sm-3 col-xs-12 "style={{margin:"auto"}} >
                  <div
                    class="infobox d-block"
                    style={{
                      background: "#0b2c1b",
                      padding: "5px",
                      borderRadius: "5px",
                      border: " 1px solid rgb(69 189 53 / 96%)",
                    }}
                  >
                    <div>
                      <div class="infobox-content d-block text-center pt-2">
                        withdrawal Deduction :{" "}
                        <span className="text-danger fw-bolder">25%</span>
                      </div>
                     
                      <div className="pt-2 ps-3 pb-2 ">
                        <span className="text-white ">
                          Holding Amount :{" "}
                        </span>
                        <span className="text-white fw-bold pe-2">{holdingAmt/1e18} Dai </span>
                        <button
                          type="button"
                          style={{ background: "#009a00 !important",  border: " 1px solid rgb(69 189 53 / 96%)",}}
                          class="btn text-white "
                          onClick={() =>
                            withdrawHoldingAnount(walletAddress, level ,setRefresh)
                          }
                        >
                          withdraw
                        </button>
                      </div>
                     
                    </div>
                  </div>
                </div>
             
              </div>:null
            
            }
              
              <table class="table table-borderless table-striped">
                <thead>
                  <tr>
                    <th className="table-head"># </th>
                    <th className="table-head">User ID</th>
                    <th className="table-head">User Address</th>
                    <th className="table-head">Place</th>
                    {/* <th className="table-head">Registration Date</th> */}
                  </tr>
                </thead>
               
                <tbody className="table-data">
                  {apidata?.length > 0 ? (
                    apidata?.map((e, index) => {
                      return (
                        <tr
                          className="  p-2"
                          style={{ marginBlock: "0px 15px" }}
                          key={index + "directincome"}
                        >
                          <td className="">
                            <span>{index + 1}</span>
                          </td>
                          <td className="">
                            <span>{e?.user_id}</span>
                          </td>
                          <td className="">
                            <span>
                              {e?.user?.slice(0, 6) +
                                "..." +
                                e?.user?.slice(30)}
                            </span>
                          </td>
                          <td className="  fw-bolder">
                            <span>{e?.place} </span>
                          </td>

                        
                        </tr>
                      );
                    })
                  ) : (
                    <tr>
                      <td colSpan={5}>
                        {" "}
                        <div className="text-center text-white mt-4 mb-2 fw-bold fs-5">
                          No Data Found.
                        </div>
                      </td>
                    </tr>
                  )}
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default WorkingTable;
