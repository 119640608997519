import React, { useEffect } from "react";
import { RxDashboard } from "react-icons/rx";
import { RiTeamFill } from "react-icons/ri";
import { FcStatistics } from "react-icons/fc";
import { GiTakeMyMoney } from "react-icons/gi";
import { MdGroupAdd } from "react-icons/md";
import navimg from "../image/logo.png";
import { Link, useNavigate } from "react-router-dom";
import { sideBar } from "../helpers/helperFunction";
import { useDispatch } from "react-redux";
import {
  setRefresh,
  setUserExist,
  setWalletAddress,
} from "../helpers/redux/dataSlice";
import { NotificationManager } from "react-notifications";
import { AiOutlineLogout } from "react-icons/ai";
const ToggleSidebar = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  useEffect(() => {
    sideBar();
  }, []);
  return (
    <>
      <div className="container">
        <div className="row">
          <header class="header">
            <div class="header_in d-flex  justify-content-between align-items-center">
              <Link to="/">
                <img src={navimg} className="nav-image" alt="" width={70} />
              </Link>
              <button type="button" class="toggle" id="toggle">
                <span></span>
              </button>
            </div>
          </header>
          <div class="sidebar" id="sidebar">
            <ul>
              <li>
                <Link to="/dashboard">
                  <RxDashboard className="me-2 fs-5 dash-heading" /> Dashboard
                </Link>
              </li>
              <li>
                <Link to="/partners">
                  <RiTeamFill className="me-2 fs-5 dash-heading" /> Direct
                  Partners
                </Link>
              </li>
              <li>
                <Link to="/userIncome">
                  {" "}
                  <GiTakeMyMoney className="me-2 fs-5 dash-heading" />
                  User Income
                </Link>
              </li>
              <li>
                <Link to="/workingtree">
                  {" "}
                  <MdGroupAdd className="me-2 fs-5 dash-heading" /> Working
                  Structure
                </Link>
              </li>
              <li>
                <Link to="/nonworkingtree">
                  {" "}
                  <FcStatistics className="me-2 fs-5 dash-heading" />
                  Non Working Structure
                </Link>
              </li>
              <li>
                <div
                  className=" text-danger ms-3 fs-5 fw-bold"
                  style={{ cursor: "pointer" }}
                  onClick={() => {
                    localStorage.removeItem("volcano_waddress");
                    dispatch(setWalletAddress({ walletAddress: "" }));
                    dispatch(setUserExist({ userExist: false }));
                    NotificationManager.success("Logout SuccessFul !!");
                    navigate("/login");
                  }}
                >
                  <AiOutlineLogout className="me-2 dash-heading" /> Logout
                </div>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </>
  );
};

export default ToggleSidebar;
