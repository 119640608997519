import React from "react";
import "../css/Home/loader.css";
export const Loader = () => {
  return (
    <>
      <div class="parent">
        <span class="loader"></span>
      </div>
    </>
  );
};
