import React, { useEffect } from "react";
import { Routes, Route, Navigate, useLocation } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import Dashboard from "./components/Dashboard";
import Login from "./components/Login";
import Registeration from "./components/Registeration";
import WorkingTree from "./Pages/WorkingTree";
import {
  setMessage,
  setUserExist,
  setUserInfo,
  setWalletAddress,
} from "./helpers/redux/dataSlice";
import { isUserExists, users, web3lelo } from "./helpers/web3";
import Partners from "./Pages/Partners";
import { fetchActiveLevel, verifyUser } from "./helpers/helperFunction";
import NonWorkingTree from "./Pages/NonWorkingTree";
import UserIncome from "./Pages/UserIncome";
import { NonWorkingTable } from "./Pages/NonWorkingTable";
import WorkingTable from "./Pages/WorkingTable";
import { NotificationManager } from "react-notifications";

const RoutesMain = () => {
  const { userExist, walletAddress } = useSelector((state) => state.user.value);
  const dispatch = useDispatch();
  const route = useLocation();
  function getAddressFromLocalStorage() {
    const localwaddress = localStorage.getItem("volcano_waddress");
    if (localwaddress) {
      dispatch(setWalletAddress({ walletAddress: localwaddress }));
      isUserExists(localwaddress).then((res) => {
        dispatch(setUserExist({ userExist: res }));
      });
    } else {
      verifyUser(dispatch, "", NotificationManager);
    }
  }
  
  function getViewAddressFromUrl() {
    const url = route?.search;
    const address = url?.substring(url?.indexOf("=") + 1);
    if (address && address?.length>25) {
      dispatch(setWalletAddress({ walletAddress: address }));
      isUserExists(address).then((res) => {
        dispatch(setUserExist({ userExist: res }));
      });
    } else {
      getAddressFromLocalStorage();
    }
  }
  
  useEffect(() => {
    if (window.ethereum) {
      window.ethereum.on("accountsChanged", function (accounts) {
        verifyUser(dispatch, "", NotificationManager);
      });     
    }
    getViewAddressFromUrl();
  }, []);

  

  useEffect(() => {
    if (walletAddress) {
      fetchActiveLevel(walletAddress, dispatch);
      users(walletAddress).then((res) => {
        dispatch(setUserInfo({ userInfo: res }));
      });
    } else {
      console.log("wallet address not fetched", walletAddress);
    }
  }, [walletAddress]);

  return (
    <>
      <Routes>
        <Route path="/register" element={<Registeration />} />
        <Route
          path="/dashboard"
          element={userExist ? <Dashboard /> : <Navigate to="/login" />}
        />

        <Route path="/" element={<Registeration />} />
        <Route
          path="/login"
          element={userExist ? <Navigate to="/dashboard" /> : <Login />}
        />
        <Route
          path="/partners"
          element={userExist ? <Partners /> : <Navigate to="/login" />}
        />
        <Route
          path="/workingtree"
          element={userExist ? <WorkingTree /> : <Login />}
        />
        <Route
          path="/userIncome"
          element={userExist ? <UserIncome /> : <Login />}
        />
        <Route
          path="/nonworkingtree"
          element={userExist ? <NonWorkingTree /> : <Login />}
        />
        <Route
          path="/nonworkingtable"
          element={userExist ? <NonWorkingTable /> : <Login />}
        />
        <Route
          path="/nonworkingtable"
          element={userExist ? <NonWorkingTable /> : <Login />}
        >
          <Route
            path=":level"
            element={userExist ? <WorkingTable /> : <Login />}
          />
        </Route>
        <Route
          path="/workingtable"
          element={userExist ? <WorkingTable /> : <Login />}
        >
          <Route
            path=":level"
            element={userExist ? <WorkingTable /> : <Login />}
          />
        </Route>
      </Routes>
    </>
  );
};

export default RoutesMain;
