import Web3 from "web3";
import { Interface } from "@ethersproject/abi";
import { toast } from "react-hot-toast";
import { NotificationManager } from "react-notifications";
import {
  CONTRACT_ADDRESS,
  TOKEN_ADDRESS,
  CONTRACT_ABI,
  TOKEN_ABI,
  MultiCall_ABI,
  Multicall_ADDRESS,
} from "./config";
import { fetchActiveLevel, verifyUser } from "./helperFunction";
const web3 = new Web3(Web3.givenProvider);
const contract_instance = new web3.eth.Contract(CONTRACT_ABI, CONTRACT_ADDRESS);
const token_instance = new web3.eth.Contract(TOKEN_ABI, TOKEN_ADDRESS);

export function web3lelo() {
  if (web3) {
    return web3;
  }
}

export async function startNow() {
  
  return new Promise((resolve, reject) => {
    if (window.ethereum) {
      try {
        window.ethereum
          .request({ method: "eth_requestAccounts" })
          .then(async function (address) {
            const userAddress = address[0];
            resolve({
              userAddress,
            });
          });
      } catch (error) {
        reject(error);
      }
    } else {
      NotificationManager.error(
        "Open in Dapp Browser or Install Any Crypto Wallet"
      );
    }
  });
}

export function registerExt(
  address,
  refAddress,
  setRefresh,
  dispatch,
  navigate
) {
  try {
    console.log(address, refAddress, "refId");
    const data = contract_instance.methods
      .registrationExt(refAddress)
      .send({ from: address });
    toast.promise(data, {
      loading: "registeration Pending...",
      success: () => {
        setRefresh(true);
        verifyUser(dispatch);
        navigate("/dashboard");
        return "registeration Successful";
      },
      error: "registeration Canceld.",
    });
  } catch (error) {
    console.log(error, "Error");
  }
}

export async function withdrawHoldingAnount(address, level, setRefresh) {
  try {
    console.log(address, level, "with::::");
    const data = contract_instance.methods
      .withdrawHolding(address, level)
      .send({ from: address });
    toast.promise(data, {
      loading: <b>Withraw Pending...</b>,
      success: () => {
        setRefresh(true);
        return <b>Withraw Successful</b>;
      },
      error: <b>Withraw Canceld.</b>,
    });
  } catch (error) {
    console.log(error, "Error");
  }
}

export async function isUserExists(address) {
  const data = await contract_instance.methods.isUserExists(address).call();
  return data;
}

export async function idToAddress(id) {
  const data = await contract_instance.methods.idToAddress(id).call();
  return data;
}

export async function lastUserId() {
  const data = await contract_instance.methods.lastUserId().call();
  return data;
}

export async function users(address) {
  const data = await contract_instance.methods.users(address).call();

  return data;
}

export async function checkUsersActiveX6Level(address, level) {
  const data = await contract_instance.methods
    .usersActiveX6Levels(address, level)
    .call();
  return data;
}

export async function usersX6HoldAmount(address, level) {
  const data = await contract_instance.methods
    .userX6HoldAmount(address, level)
    .call();
  return data;
}

export async function getLevelMulticall(calls, arr) {
  try {
    const multi = new web3.eth.Contract(MultiCall_ABI, Multicall_ADDRESS);
    const itf = new Interface(CONTRACT_ABI);
    const calldata = calls.map((call) => [
      call.address.toLowerCase(),
      itf.encodeFunctionData(call.name, call.params),
    ]);
    // console.log(calls, "callsl aRRAY");
    var { returnData } = await multi.methods.aggregate(calldata).call();

    const res = returnData.map((call, i) =>
      itf.decodeFunctionResult(calls[i].name, call)
    );

    const rarr = res.map((item, i) => {
      const [active] = item;
      const obj1 = { ...arr[i] };
      const obj = { active: active, ...obj1 };
      return { ...obj };
    });

    return rarr;
  } catch (e) {
    console.log(e);
  }
}

export function getBalance(userAddress) {
  return new Promise((resolve, reject) => {
    web3.eth
      .getBalance(userAddress)
      .then((d) => {
        resolve(d / 1e18);
      })
      .catch((e) => {
        reject(e);
      });
  });
}

export async function TokenBalnce(userAddress) {
  const data = await token_instance.methods.balanceOf(userAddress).call();
  return data;
}

export async function buyNewLevel(level, amount, address, dispatch) {
  console.log(level, amount, address, "buy:::::");
  try {
    token_instance.methods
      .balanceOf(address)
      .call()
      .then((res) => {
        const userBalance = res / 1e18;
        console.log(res, "userBalance");
        if (userBalance >= amount) {
          token_instance.methods
            .allowance(address, contract_instance._address)
            .call()
            .then((res) => {
              const allowance = res / 1e18;

              if (allowance >= amount) {
                try {
                  const data = contract_instance.methods
                    .buyNewLevel(level)
                    .send({ from: address });
                  toast.promise(data, {
                    loading: <b>registeration Pending...</b>,
                    success: () => {
                      // dispatch(setRefresh({ data: true }));
                      fetchActiveLevel(address, dispatch);
                      return <b>registeration Successful</b>;
                    },
                    error: <b>registeration Canceld.</b>,
                  });
                } catch (error) {
                  console.log(error, "Error");
                }
              } else if (allowance < amount) {
                console.log("6");
                console.log((amount * 1e18).toString(), "amount*1e18 3");
                try {
                  const approveData = token_instance.methods
                    .approve(
                      contract_instance?._address,
                      (amount * 1e18).toLocaleString("fullwide", { useGrouping: false })
                    )
                    .send({
                      from: address,
                      value: 0,
                    });

                  toast
                    .promise(approveData, {
                      loading: <b>Approval Pending...</b>,
                      success: <b>Approval Successful</b>,
                      error: <b>Approval request failed.</b>,
                    })
                    .then(() => {
                      const data = contract_instance.methods
                        .buyNewLevel(level)
                        .send({
                          from: address,
                          value: 0,
                        });

                      toast.promise(data, {
                        loading: <b> Transaction Pending...</b>,
                        success: () => {
                          // dispatch(setRefresh({ data: true }));
                          fetchActiveLevel(address, dispatch);
                          return <b>Transaction Successful</b>;
                        },
                        error: <b>Transaction Canceld.</b>,
                      });
                    })
                    .catch((err) => {
                      console.log(err, "Err-4");
                    });
                } catch (error) {
                  console.log(error, "approval Error");
                }
              }
            })
            .catch((err) => {
              console.log(err, "Err-2");
            });
        } else {
          NotificationManager.error("Insufficient Balance !");
        }
      })
      .catch((err) => {
        console.log(err, "Err -1");
      });
  } catch (error) {
    console.log(error);
  }
}

export async function IsOwner(userAddress) {
  const data = await contract_instance.methods.owner().call();
  return data;
}

export async function checkUsersnonWorkingIncome(address, level) {
  const data = await contract_instance.methods
    .nonWorkingSend(address, level)
    .call();
  return data;
}